import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import InfoSection from '../components/InfoSection';
import Separator from '../components/Separator';

const Home = () => {
    return (
        <>
            <NavBar />
            <Hero />
            <Separator />
            <InfoSection />
            <Footer />
        </>
    );
};

export default Home;
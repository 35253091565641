import React from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
    return (
        <>
            <header className="bg-black p-4 flex items-center justify-between fixed top-0 w-full z-50">
                <h1 className="text-white text-2xl font-bold mx-4">
                    <Link to="/">
                        <span className="text-gray-500 p-20 text-3xl">
                            <span className="font-bold text-white">RunTech</span>Lab
                        </span>
                    </Link>
                </h1>
                <nav className="mx-4">
                    <ul className="flex items-center space-x-4 p-4 px-10 text-1xl">
                        <Link to="/" className="p-2 text-white hover:text-blue-800 transition duration-300 ease-in-out text-2xl">Home</Link>
                        <Link to="/about" className="p-2 text-white hover:text-blue-800 transition duration-300 ease-in-out text-2xl">About</Link>
                        <Link to="/services" className="p-2 text-white hover:text-blue-800 transition duration-300 ease-in-out text-2xl">Services</Link>
                        <Link to="/contact" className="p-2 text-white hover:text-blue-800 transition duration-300 ease-in-out text-2xl">Contact</Link>
                    </ul>
                </nav>
            </header>
        </>
    );
};

export default NavBar;

import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const Contact = () => {
    return (
        <>
            <NavBar />

            <section className="py-20 mt-8">
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4">Contactez-nous</h1>
                
                <p className="mb-4">Vous pouvez nous contacter en remplissant le formulaire ci-dessous ou en nous envoyant un e-mail à <a href="mailto:contact@runtechlab.com" className="text-blue-500">contact@runtechlab.com</a>.</p>

                <form className="max-w-lg">
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Nom</label>
                        <input type="text" id="name" name="name" className="mt-1 p-2 border border-gray-300 rounded-md w-full" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Adresse e-mail</label>
                        <input type="email" id="email" name="email" className="mt-1 p-2 border border-gray-300 rounded-md w-full" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                        <textarea id="message" name="message"  className="mt-1 p-2 border border-gray-300 rounded-md w-full"></textarea>
                    </div>
                    <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600">Envoyer</button>
                </form>
            </div>
            </section>

            <Footer />
        </>
    );
};

export default Contact;

import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const NotFound = () => {
    return (
        <>
            <NavBar />
            <div className="hero min-h-screen bg-base-200">
                <div className="hero-content text-center">
                    <div className="max-w-md">
                        <h1 className="text-5xl font-bold">404</h1>
                        <p className="py-6">La page que vous recherchez n'existe pas.</p>
                        <Link to="/" className="text-blue-500 hover:underline">Retour à la page d'accueil</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default NotFound;
import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
    return (
        <div className="hero min-h-screen" style={{ backgroundImage: 'url(assets/images/banner.png)' }}>
            <div className="hero-overlay bg-opacity-60"></div>
            <div className="hero-content text-center text-neutral-content">
                <div className="max-w-md mx-auto">
                    <h1 className="mb-5 text-5xl font-bold">Bienvenue sur RunTechLab</h1>
                    <p className="mb-5 text-lg">
                    Explorez de nouveaux horizons avec RunTechLab. De la création d'influenceuses IA à la composition musicale par intelligence artificielle, réinventez votre entreprise avec nos solutions innovantes.
                    </p>
                    <button className="btn btn-primary px-8 py-3 text-lg font-semibold rounded-md shadow-lg hover:bg-primary-dark">
                        <Link to="/presentation">Commencer</Link>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Hero;

import React from 'react';

const InfoSection: React.FC = () => {
    const services = [
        {
            title: "Création d'Influenceuses IA",
            description: "Nous proposes des influenceuses IA personnalisées pour instagram. Ces influenceuses sont conçues pour promouvoir des marque et interagir avec les followers."
            },
            {
            title: "Composition Musicale par IA",
            description: "Découvrez notre service révolutionnaire de composition musicale automatisée, générée par des algorithmes d'intelligence artificielle de pointe."
            },
            {
            title: "ChatBots pour Interactions avec Influenceuses IA",
            description: "Favorisez des conversations authentiques avec nos chatbots conçus spécifiquement pour interagir avec nos influenceuses IA, offrant des expériences uniques et engageantes."
            }
        // Ajoutez d'autres services ici
    ];

    const teamMembers = [
        {
            name: 'Erwan',
            role: 'CEO de RunTechLab',
            image: '/assets/images/erwan.png',
        },
        {
            name: 'Léa',
            role: 'CTO de RunTechLab',
            image: '/assets/images/lea.png',
        },
        {
            name: 'Corentin',
            role: 'Responsable de NeoAgencyLab',
            image: '/assets/images/corentin.png',
        }
        // Ajoutez d'autres membres de l'équipe ici
    ];

    return (
        <section className="py-8">
            <div className="container mx-auto">
                <h2 className="text-2xl font-bold mb-4">Services</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {services.map((service, index) => (
                        <div key={index} className="bg-white shadow-lg hover:shadow-xl transition duration-300 p-6 rounded-lg">
                            <h3 className="text-lg font-semibold mb-2">{service.title}</h3>
                            <p>{service.description}</p>
                        </div>
                    ))}
                </div>

                <h2 className="text-2xl font-bold my-8">Équipe</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {teamMembers.map((member, index) => (
                        <div key={index} className="bg-white shadow-lg hover:shadow-xl transition duration-300 p-4 rounded-lg">
                            <img src={member.image} alt={member.name} className="w-20 h-20 rounded-full mx-auto mb-2" />
                            <h4 className="text-md font-semibold">{member.name}</h4>
                            <p className="text-gray-600">{member.role}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default InfoSection;

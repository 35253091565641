import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ReactPlayer from 'react-player';
import InfoSection from '../components/InfoSection';

const Presentation = () => {
    return (
        <>
            <NavBar />

            <div className="grid grid-cols-2 gap-4 bg-dark-blue pt-16 w-full h-96 mt-16 mb-16">
                <div>
                    <ReactPlayer
                        url="https://vimeo.com/856116158?share=copy"
                        controls
                        width="100%"
                        height="100%"
                    />
                </div>
                <div className="p-4">
                    <h2 className="text-black text-4xl font-bold mb-4">Nos Objectifs</h2>
                    <p className="text-black text-2xl">
                        Chez <span className='font-bold'>RunTechLab</span>, notre mission est de repousser les limites de l'intelligence artificielle pour façonner un avenir meilleur. Nous sommes une équipe passionnée d'experts en IA, dévoués à résoudre les défis les plus complexes de notre époque grâce à des solutions innovantes.
                    </p>
                </div>
            </div>

            <InfoSection />

            <Footer />
        </>
    );
};

export default Presentation;
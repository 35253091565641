import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const Services = () => {
    const services = [
        {
            title: "Création d'Influenceuses IA",
            imageurl: 'assets/images/ia-influenceuse.jpeg',
            description: "Nous proposes des influenceuses IA personnalisées pour instagram. Ces influenceuses sont conçues pour promouvoir des marque et interagir avec les followers."
        },
        {
            title: "Composition Musicale par IA",
            imageurl: 'assets/images/ia-musique.png',
            description: "Découvrez notre service révolutionnaire de composition musicale automatisée, générée par des algorithmes d'intelligence artificielle de pointe."
        },
        {
            title: "ChatBots pour Interactions avec Influenceuses IA",
            imageurl: 'assets/images/ia-chatbot.jpeg',
            description: "Favorisez des conversations authentiques avec nos chatbots conçus spécifiquement pour interagir avec nos influenceuses IA, offrant des expériences uniques et engageantes."
        }
        // Ajoutez d'autres services ici
    ];

    return (
        <>
            <NavBar />

            <section className="py-20 mt-8"> {/* Ajout de la classe mt-8 pour créer de l'espace */}
                <div className="container mx-auto">
                    <h2 className="text-2xl font-bold mb-4">Nos Services</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {services.map((service, index) => (
                            <div key={index} className="bg-black text-white shadow-lg hover:shadow-xl transition duration-300 p-6 rounded-lg">
                                <h3 className="text-lg font-semibold mb-2">{service.title}</h3>
                                <p>{service.description}</p>
                                <img src={service.imageurl} alt={service.title} className="rounded-lg mb-2 p-4" />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Services;
